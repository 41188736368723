<template>
  <nav class="md:hidden flex-grow">
    <div class="md:hidden flex flex-grow items-center justify-end text-xl">
      <button class="btn-icon" name="Menu de navigation" @click="mobileMenu">
        <i
          class="fa-regular fa-bars-staggered text-2xl text-blue"
          alt="icône de Menu"
        ></i>
      </button>
    </div>
    <div
      v-if="showMobileMenu"
      ref="mobileMenuRef"
      class="absolute flex flex-col space-y-8 h-screen w-screen top-0 left-0 p-6 text-sm bg-blue-100 z-50"
    >
      <div class="flex justify-end">
        <button name="Fermer la navigation" @click="mobileMenu">
          <i class="fa-regular fa-times text-2xl text-blue"></i>
        </button>
      </div>
      <div>
        <p class="font-display text-xl font-bold text-blue-800 mb-2">
          Nos outils
        </p>
        <NavToolsMenuContent />
      </div>
      <div>
        <p class="font-display text-xl font-bold text-blue-800 mb-2">
          À propos
        </p>
        <NavAboutMenuContent />
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import NavAboutMenuContent from './NavAboutMenuContent.vue';
import NavToolsMenuContent from './NavToolsMenuContent.vue';
const showMobileMenu = ref(false);
const mobileMenuRef = ref();

function mobileMenu() {
  showMobileMenu.value = !showMobileMenu.value;
}

onClickOutside(mobileMenuRef, () => {
  if (showMobileMenu.value) showMobileMenu.value = false;
});
</script>
